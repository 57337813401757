div.carousel{
  display: inline-block;
  text-align:center;
}

.slider-wrapper{
  width: 88% !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
}
 
.carousel-status{
  visibility: hidden;
}

.img-portfolio{
  max-height: 80vh;
  object-fit: cover;
  background: #161515;
  filter: grayscale(80%);
  border-radius:calc(.25rem );   
}
.img-portfolio:hover {
  filter: grayscale(0%);      
}

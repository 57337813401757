.card{
    background-color: var(--back-color); 
    border: none;
    height: 300px;
    margin-bottom: 25px;
}
.profileImage{
    height: 200px;
    border-radius: 50%;
    position: relative;
    top: -100px;
}
.card-content{
position: relative;
top: -80px;
}
.icon-container i{
    color:var(--grey-color);
    margin-top: 20px;    
}
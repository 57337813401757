header{    
    height: 300px;
    background-image: url('../img/top_img.jpg');
    background-size: cover;
    background-repeat: no-repeat;  
    background-attachment: fixed;
    margin-bottom: 45px;    
}
span{
    transition: transform 0.1s
}
.navbar{
    top: 20px;
    left: 10px;
}
@media (max-width: 576px) {
    .navbar {
        left: -10px;
    }
    button.navbar-toggler{
        padding: 4px;
    }
}

.navbar-toggler:active {
    background-color: var(--mid-blue);
  }
.navbar-toggler{
    border-color: var(--mid-blue) !important;
}
ul{
    padding: 0;
}
.li-navbar{
    writing-mode: vertical-lr; /*Cambiar orienta de escritura */ 
    list-style: none;
    margin-top: 15px;    
}
span:active{
    transform: rotate(90deg) 
}
.container{
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
    position: relative;
    top: -45px;
    padding-top: 10px;
    margin-top: 40px;        
}
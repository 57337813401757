:root{
  /*---colores-----*/
  --dark-blue:#002a46;
  --mid-blue:rgb(6, 128, 133);
  --light-blue:rgb(3, 152, 158);
  --back-color:#2b2828;
  --grey-color:rgb(233, 233, 233);;
  --dark-visited:rgb(6, 128, 133);
  --white-color: white;
  /*---tipografía---*/
  --normal:12px;
  /*---espaciado---*/
  --space:10px;
  --space-2:20px;
  /*---box sahdow---*/
  --box:0px 0px 2px 1px #e6e5e9;
  /*----------------*/
}
body{
 background-color: var(--back-color);
 margin: 20px; 
}
main{
  margin-left: 20px;
}
.container{
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
  position: relative;
  top: -45px;
  padding-top: 10px;
  margin-top: 40px;        
}
